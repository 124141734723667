import Vue from "vue";
import VueRouter from "vue-router";
Vue.use(VueRouter);
const routes = [{
    path: "/",
    component: () =>
        import ("../views/module/front/Home.vue"),
    children: [{
            path: '/',
            name: "home",
            component: () =>
                import ('../views/module/front/home/index')
        },
        {
            path: '/api/apiList',
            name: "api",
            component: () =>
                import ('../views/module/front/api/apiList')
        },
        {
            path: '/product/index',
            name: "product",
            component: () =>
                import ('../views/module/front/product/index')
        },
        {
            path: '/enterprise/index',
            name: "enterprise",
            component: () =>
                import ('../views/module/front/enterprise/index')
        },
        {
            path: '/projectRegistration/index',
            name: "projectRegistration",
            component: () =>
                import ('../views/module/front/projectRegistration/index')
        },
        {
            path: '/authorizationQuery/index',
            name: "authorizationQuery",
            component: () =>
                import ('../views/module/front/authorizationQuery/index')
        },
        {
            path: '/aboutUs/index',
            name: "aboutUs",
            component: () =>
                import ('../views/module/front/aboutUs/index')
        },
        {
            path: '/payfor/index',
            name: "payfor",
            component: () =>
                import ('../views/module/front/payfor/index')
        },
    ]
}, {
    path: "/login",
    component: () =>
        import ('../views/module/login')
}];

const router = new VueRouter({
    mode: "history",
    routes,
});

const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this, to).catch(err => err)
}
export default router;