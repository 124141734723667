<!--  -->
<template>
  <div class="inner">
    <div class="footer">
      <div class="main layout-flex-all">
        <div class="right">
          <div class="column layout-flex-all">
            <div class="l">
              <dl>
                <dt>新手入门</dt>
                <dd>
                  <span><a href="https://doc.icuapi.com/#/cframe/%E4%BB%8B%E7%BB%8D/%E9%A1%B9%E7%9B%AE%E4%BB%8B%E7%BB%8D" target="_blank">框架介绍</a></span>
                </dd>
                <dd>
                  <span><a href="https://doc.icuapi.com/#/cms/%E6%A6%82%E8%BF%B0" target="_blank">CMS网站</a></span>
                </dd>
                <dd>
                  <span><a href="https://doc.icuapi.com/#/cfragment/%E4%B8%8A%E4%BC%A0%E7%BB%84%E4%BB%B6" target="_blank">常用组件</a></span>
                </dd>
                <dd>
                  <span><a href="https://doc.icuapi.com/#/capi/%E6%A6%82%E8%A6%81/%E5%BF%AB%E9%80%9F%E5%85%A5%E9%97%A8" target="_blank">API接口</a></span>
                </dd>
              </dl>
              <!-- <dl>
              <dt>新手入门</dt>
              <dd>
                <span>购买流程</span>
              </dd>
              <dd>
                <span>购买流程</span>
              </dd>
              <dd>
                <span>购买流程</span>
              </dd>
              <dd>
                <span>购买流程</span>
              </dd>
            </dl>
            <dl>
              <dt>新手入门</dt>
              <dd>
                <span>购买流程</span>
              </dd>
              <dd>
                <span>购买流程</span>
              </dd>
              <dd>
                <span>购买流程</span>
              </dd>
              <dd>
                <span>购买流程</span>
              </dd>
              </dl>-->
            </div>
            <div class="r layout-flex-all">
              <dl>
                <dt>联系我们</dt>
                <dd>
                  <div class="tel">
                    <span>
                      <img src="@/assets/img/Telephone.png" /> 0536-8184803
                    </span>
                    <p>24h全国统一客服热线</p>
                  </div>
                </dd>
              </dl>
              <dl>
                <dt>扫码进入会员中心</dt>
                <dd>
                  <div class="ewm">
                    <img src="@/assets/img/ewm.png" alt />
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
      <div class="link layout-flex-all isPc">
        <div class="txt">
          <span>友情链接：</span>
        </div>
        <div class="txt" @click="jump('http://www.hcwdc.com')">华创数字云</div>
        <div class="txt" @click="jump('http://www.hcses.com')">华创科技</div>
        <div class="txt" @click="jump('http://frame.icuapi.com')">Castlefortress企业敏捷开发框架</div>
        <div class="txt" @click="jump('http://open.icuapi.com')">API开放平台</div>
      </div>
    </div>
    <div class="copyRight">Copyright 2022 © 华创科技. All rights reserved. 备案号：<a href="https://beian.miit.gov.cn/" target="_blank" > 鲁ICP备16033576号 </a>
      增值电信业务经营许可证：<a href="https://tsm.miit.gov.cn/dxxzsp/xkz/xkzgl/resource/qiyesearch.jsp?num=%25E6%25BD%258D%25E5%259D%258A%25E5%258D%258E%25E5%2588%259B%25E4%25BF%25A1%25E6%2581%25AF%25E6%258A%2580%25E6%259C%25AF%25E6%259C%2589%25E9%2599%2590%25E5%2585%25AC%25E5%258F%25B8&type=xuke" target="_blank">鲁B2-20200417</a>
    </div>
  </div>
</template>

<script>
import mixin from '@/mixin'
export default {
  mixins: [mixin],
  data () {
    return {
    };
  },

  components: {},

  computed: {},

  methods: {

  }
}

</script>
<style lang='scss' scoped>
.inner {
  padding: 50px 0 0;
  width: 100%;
  color: #fff;
  background: #37405e;
  .footer {
    max-width: $minWidth;
    margin: 0 auto 4rem;
    .main {
      align-items: flex-start;
      .right {
        padding: 0 40px 0 0;
        width: 100%;
        .column {
          padding-left: 4rem;
          align-items: flex-start;
          dl {
            dt {
              font-size: 1.4rem;
              font-weight: bold;
              margin-bottom: 30px;
            }
            dd {
              font-size: 1.4rem;
              margin: 0 0 10px;
              a{
                color: inherit;
              }
              span {
                color: #acb0bc;
                cursor: pointer;
                &:hover {
                  opacity: 0.85;
                }
              }
              .tel {
                span {
                  margin-bottom: 0.5rem;
                  display: flex;
                  align-items: center;
                  font-size: 2.5rem;
                  color: #fff;
                  img {
                    margin-right: 0.4rem;
                    width: 1.6rem;
                  }
                }
                p {
                  color: #9fa6be;
                }
              }
              .ewm {
                padding: 1rem;
                background: #fff;
                border-radius: 0.2rem;
                border: 1px solid #37405e;
                img {
                  width: 10rem;
                }
              }
            }
          }
        }
        .r {
          align-items: flex-start;
          dl {
            padding: 0 1rem;
          }
        }
      }
    }
    .link {
      justify-content: flex-start;
      padding: 0 60px;
      width: 100%;
      height: 50px;
      border-top: 1px solid #5b637b;
      border-bottom: 1px solid #5b637b;
      box-sizing: border-box;
      .txt {
        padding: 0 1rem;
        font-size: 14px;
        color: #a6aab7;
        cursor: pointer;
        &:hover {
          opacity: 0.85;
        }
        span {
          color: #fff;
        }
      }
    }
  }
  .copyRight {
    width: 100%;
    padding: 10px 0;
    text-align: center;
    font-size: 1.2rem;
    color: #a6aab7;
    background: #181b24;
    a{
      color: inherit;
    }
  }
}
@media screen and(max-width:996px) {
  .inner {
    .footer {
      margin: 0;
      .main {
        flex-wrap: wrap;
        .right {
          padding: 0 0.5rem;
          .column {
            flex-wrap: wrap;
          }
        }
      }
    }
  }
}
</style>
