import axios from 'axios';
import { Message } from 'element-ui'
import Store from '@/store/index';
import router from '@/router/index'


axios.defaults.validateStatus = function(status) {
    return status >= 200 && status <= 500;
};

let baseUrl = process.env.VUE_APP_ROOT_URL_ENV; //这里是一个默认的url，可以没有

let headers = {
    // 'Content-Type': 'application/x-www-form-urlencoded'
}
//创建axios的一个实例
const axiosInstance = axios.create({
    baseURL: baseUrl, //接口统一域名
    timeout: 1000 * 10, //设置超时
    headers: headers
})


//------------------- 一、请求拦截器 忽略
axiosInstance.interceptors.request.use(config => {
    if (config.url == baseUrl + "memberRefreshToken") {
        return config;
    }
    if (localStorage.getItem("token") || Store.state.token) {
        config.headers.Authorization = localStorage.getItem("token") || Store.state.token
    }
    if (localStorage.getItem("fortressExpiredTime") && new Date(Date.parse(localStorage.getItem("fortressExpiredTime"))) < new Date()) {
        //通过refreshToken重新获取token
        let promisefresh = new Promise(function(resolve, reject) {
            console.log(reject)
            //刷新token
            axios.post(baseUrl + "memberRefreshToken", {}, {
                headers: {
                    'token': '' + localStorage.getItem("fortressRefreshToken")
                }
            }).then(response => {
                if (response.data && response.data.code == 0) {
                    localStorage.setItem(
                        "fortressRefreshToken",
                        response.data.data.refreshToken
                    );
                    localStorage.setItem(
                        "fortressExpiredTime",
                        response.data.data.expiredTime
                    );
                    localStorage.setItem(
                        "token",
                        response.data.data.token
                    );
                    config.headers.Authorization = `${response.data.data.token}`;
                }
                resolve(config);
            })
        });
        return promisefresh;
    }
    return config;
}, function(error) {
    // 对请求错误做些什么
    return Promise.reject(error);
});

//----------------- 二、响应拦截器 忽略
axiosInstance.interceptors.response.use(response => {
    // 用户未登录 跳转到登录页面
    if ((response.data && (response.data.code == 401 || response.data.code == 1001 || response.data.code == 1000)) || response.status === 401) {
        router.replace({ name: 'login' })
    }
    return response.data;
}, function(error) {
    // 对响应错误做点什么
    return Promise.reject(error);
});

export const ept = function(url, data) {
    axios({
        method: "get",
        url: baseUrl + url,
        data: data,
        responseType: "blob",
        headers: {
            'Authorization': localStorage.getItem("token") || Store.state.token
        }
    })
        .then(res => {
            // console.log(decodeURI(res.headers['filename']));
            const link = document.createElement("a");
            let blob = new Blob([res.data], { type: "application/vnd.ms-excel" });
            link.style.display = "none";
            link.href = URL.createObjectURL(blob);
            link.setAttribute("download", decodeURI(res.headers['filename']));
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        })
        .catch(error => {
            Message({
                message: "导出失败",
                type: 'error'
            });
            console.log(error);
        });
}



export default function(data) {
    let method = data.methods.toLowerCase();
    if (method == 'post') {
        return axiosInstance.post(data.url, data.params)
    } else if (method == 'get') {
        return axiosInstance.get(data.url, { params: data.params })
    } else if (method == 'delete') {
        return axiosInstance.delete(data.url, { params: data.params })
    } else if (method == 'put') {
        return axiosInstance.put(data.url, data.params)
    } else if (method == 'ept') {
        ept(data.url, data.params);
    } else {
        console.error('未知的method' + method)
        return false
    }
}
