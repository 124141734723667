<template>
  <div class="pagination">
    <el-pagination
      background
      style="text-align: right"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="parseInt(this.currentPage) || 1"
      :page-sizes="pageSizes || [10, 20, 30]"
      :page-size="parseInt(this.pageSize) || 10"
      layout="prev, pager, next"
      :total="parseInt(this.total)"
      :pager-count="5"
      prev-text="上一页"
      next-text="下一页"
    ></el-pagination>
  </div>
</template>
<script>
export default {
  name: "FortressPagination",
  props: {
    total: [Number, String],
    currentPage: [Number, String],
    pageSizes: Array,
    pageSize: [Number, String],
  },
  data () {
    return {
      pagination: true
    }
  },
  methods: {
    handleSizeChange (val) {
      this.$emit("refreshData", {
        current: parseInt(this.currentPage) || 1,
        size: val,
      });
    },
    handleCurrentChange (val) {
      this.$emit("refreshData", {
        current: val,
        size: parseInt(this.pageSize) || 10,
      });
    },
  },
  created () {
    var w = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth
    if (w <= 768) {
      this.pagination = false
    } else {
      this.pagination = true
    }

  },
  mounted () {

  },
};
</script>

<style scoped lang="scss">
.pagination {
  display: flex;
  justify-content: center;
}
.pagination ::v-deep .el-pagination.is-background .el-pager li {
  background: #fff;
  border: 1px solid #dbdbdb;
}
.pagination ::v-deep .el-pager li,
.pagination ::v-deep .el-pagination button {
  padding: 0 15px;
  height: 40px;
  line-height: 40px;
}
.pagination
  ::v-deep
  .el-pagination.is-background
  .el-pager
  li:not(.disabled).active {
  background-color: #0eaaf0;
  color: #fff;
  border-color: #0eaaf0;
}
.pagination ::v-deep .btn-prev,
.pagination ::v-deep .btn-next {
  background: #fff;
  border: 1px solid #dbdbdb;
  box-shadow: none;
  color: #606266;
  span {
    display: flex;
    align-items: center;
  }
  // opacity: 0.5;
}
.pagination ::v-deep .el-pager li.active {
  color: #fff !important;
}
</style>
