import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";

// 引入element-ui
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// 引入动画
import 'wowjs/css/libs/animate.css'
import animated from 'animate.css';
Vue.use(animated)
    //引入初始化样式文件
import "normalize.css";
import "@/assets/css/common.scss";
//引入公共组件
import pHeader from "@/components/pc/p-header/p-header.vue";
Vue.component('pHeader', pHeader)
import pFooter from "@/components/pc/p-footer/p-footer"
Vue.component('pFooter', pFooter)
import btn from "@/components/common/btn";
Vue.component('btn', btn)
import pagination from "@/components/common/pagination/pagination";
Vue.component('pagination', pagination)
    // 引入jq
import $ from 'jquery'
import layer from "layui-layer";
//回到顶部
router.afterEach(() => {
    window.scrollTo(0, 0);
});
Vue.prototype.$ = $
Vue.prototype.layer = layer
Vue.config.productionTip = false;
Vue.use(ElementUI)
new Vue({
    router,
    store,
    render: (h) => h(App),
}).$mount("#app");
