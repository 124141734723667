// import axios from "axios";
import request from "./request";
export const demoApi = () => {
    return request({
        url: "",
        methods: ""
    })
}
// 框架列表
export const getFrameList = (data) => {
    return request({
        url: "/api/shop/shopFrame/page",
        methods: "get",
        params: data
    })
}
// 框架详情
export const getFrameInfo = (data) => {
    return request({
        url: "/api/shop/shopFrame/info",
        methods: "get",
        params: data
    })
}

// 创建订单
export const saveOrder = (data) => {
    return request({
        url: "/api/order/shopOrder/saveOrder",
        methods: "post",
        params: data
    })
};
// 拉起微信支付
export const payMoneyWeChat = (data) => {
    return request({
        url: "/pay/paywxconfig/pcPay",
        methods: "post",
        params: data
    })
};

// 根据授权编码或者公司名查询授权证书
export const getAuthorization = (data) => {
    return request({
        url: "/api/order/memberAuthorization/query",
        methods: "get",
        params: data
    })
};

// 根据流水号登录
export const loginByDomain = (data) => {
    return request({
        url: "/api/member/loginByDomain",
        methods: "get",
        params: data
    })
};

// 根据订单号获取订单详情。
export const orderDetail = (data) => {
    return request({
        url: "/api/order/shopOrder/infoByOrderSn",
        methods: "get",
        params: data
    })
};

// 退出登录。
export const loginOut = (data) => {
    return request({
        url: "api/member/memberLoginOut",
        methods: "post",
        params: data
    })
};
