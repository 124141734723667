import { WOW } from 'wowjs'
export default {
    data() {
        return {

        };
    },
    components: {},
    computed: {},
    mounted() {
        var wow = new WOW({
            boxClass: 'wow',
            animateClass: 'animated',
            offset: 0,
            mobile: true
        })
        wow.init();
    },
    methods: {
        jump(path) {
            window.open(path)
        }
    }
}
