<!--  -->
<template>
  <div>
    <div class="pheader" ref="header" v-if="base" :style="{'position':position}">
      <div class="inner layout-flex-all">
        <div class="left layout-flex">
          <h1 class="logo layout-flex" @click="$router.push('/')">
            <img src="@/assets/logo.png" />
          </h1>
          <div class="name">敏捷开发</div>
        </div>
        <div class="center isPc">
          <div class="nav">
            <div class="item" v-for="(item,index) in nav" :key="index">
              <a href="http://doc.icuapi.com" target="_blank" v-if="index==3">{{item.name}}</a>
              <router-link :to="item.path" v-else>{{item.name}}</router-link>
            </div>
          </div>
        </div>
        <!--   没有TOKEN 未登录。     -->
        <div class="right layout-flex" v-if="!token">
          <!-- <div class="search">
            <i class="iconfont pdxsousuo" style="color:#fff"></i>
          </div>-->
          <!-- <span class="border"></span> -->
          <div class="loginBtn" @click="login">登录注册</div>
          <!--          <div class="regiestBtn" @click="login">立即注册</div>-->
          <div :class="['menu ','isMobile',{'close':down?true:false}] " @click="downHandle">
            <span class="line"></span>
          </div>
        </div>
        <!-- 有token 已登录-->
        <div class="right layout-flex" v-else>
          <!--          <div class="loginBtn">已经登录了</div>-->
          <!--          {{userInfo.nickName}}-->
          <!--          <img class="avatar" :src="userInfo.avatar">-->
          <el-dropdown class="dropdown" trigger="click" @command="handleCommand">
            <img class="avatar" :src="userInfo.avatar" alt />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="iconfont  pdxwinfo-icon-gerenxinxi" command="console">个人中心</el-dropdown-item>
              <el-dropdown-item icon="iconfont pdxtuichu" command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div :class="down?'slideDown activeDown':'slideDown'">
        <div class="menuList">
          <ul>
            <li v-for="(item,index) in nav" :key="index">
              <router-link :to="item.path">{{item.name}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="pheader base" ref="header" v-else :style="{'position':position}">
      <div class="inner layout-flex-all">
        <div class="left layout-flex">
          <h1 class="logo layout-flex" @click="$router.push('/')">
            <img src="@/assets/logo-colour.png" />
          </h1>
        </div>
        <div class="center isPc">
          <div class="nav">
            <div class="item" v-for="(item,index) in nav" :key="index">
              <a href="http://doc.icuapi.com" target="_blank" v-if="index==3">{{item.name}}</a>
              <router-link :to="item.path" v-else>{{item.name}}</router-link>
            </div>
          </div>
        </div>
        <!--   没有TOKEN 未登录。     -->
        <div class="right layout-flex" v-if="!token">
          <!-- <div class="search">
            <i class="iconfont pdxsousuo" style="color:#fff"></i>
          </div>-->
          <!-- <span class="border"></span> -->
          <div class="loginBtn" @click="login">登录注册</div>
          <!--          <div class="regiestBtn" @click="login">立即注册</div>-->
          <div :class="['menu ','isMobile',{'close':down?true:false}] " @click="downHandle">
            <span class="line"></span>
          </div>
        </div>
        <!-- 有token 已登录-->
        <div class="right layout-flex" v-else>
          <!--          <div class="loginBtn">已经登录了</div>-->
          <!--          {{userInfo.nickName}}-->
          <!--          <img class="avatar" :src="userInfo.avatar">-->
          <el-dropdown class="dropdown" trigger="click" @command="handleCommand">
            <img class="avatar" :src="userInfo.avatar" alt />
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item icon="iconfont  pdxwinfo-icon-gerenxinxi" command="console">个人中心</el-dropdown-item>
              <el-dropdown-item icon="iconfont pdxtuichu" command="logout">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
      <div :class="down?'slideDown activeDown':'slideDown'">
        <div class="menuList">
          <ul>
            <li v-for="(item,index) in nav" :key="index">
              <router-link :to="item.path">{{item.name}}</router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { base64encodeUrlSafe } from "@/common";
import { loginOut} from "@/api";
export default {
  props: {
    base: {
      typeof: Boolean,
      default: false
    },
    position: {
      typeof: String,
      default: 'static'
    }
  },
  data () {
    return {
      token: localStorage.getItem('token') || '',
      userInfo: localStorage.getItem('userInfo') ? JSON.parse(localStorage.getItem('userInfo')) : '',
      down: false,
      //
      nav: [{ name: "首页", path: "/" }, { name: "开源项目", path: "/product/index" }, { name: "企业版", path: "/enterprise/index" }, { name: "文档", path: "" }, { name: "授权查询", path: "/authorizationQuery/index" }, { name: "关于我们", path: "/aboutUs/index" }]

    };
  },

  components: {},

  computed: {},
  methods: {
    handleCommand (command) {
      if ("logout" == command) {
        this.$confirm("确定退出系统?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
          .then(() => {
            //
            loginOut().then(res => {
              console.log(res)
            })
            localStorage.setItem("token", "");
            localStorage.setItem("userInfo", "");
            localStorage.setItem("fortressRefreshToken", "");
            localStorage.setItem("fortressExpiredTime", "");
          })
          .catch(() => { });
      }
      else {
        //跳转去个人中心
        window.open("https://console.icuapi.com/home/")
        // window.location.href = "https://console.icuapi.com/home/";
        // this.$router.push('/user/info')
      }
    },
    login () { //跳转到console去登录。
      // let fullPath = this.$route.fullPath
      let fullPath = '/'
      let a = Date.now()
      console.log(fullPath)
      //判断url里有没有参数，没有参数就拼接一个随机数。。。。返回的时候拼接流水号好拼接。
      if (fullPath.indexOf('?') == -1) {
        fullPath = fullPath + "?code=" + a
      }
      //要返回的页面 跳转回本页。
      console.log("http://frame.icuapi.com" + fullPath)
      const originUrl = base64encodeUrlSafe("http://frame.icuapi.com" + fullPath)
      console.log(originUrl)
      window.location.href = "https://console.icuapi.com/" + (originUrl ? "?originUrl=" + originUrl : "");
    },
    querySearch (queryString, cb) {
      var serarchList = this.serarchList;
      var results = queryString ? serarchList.filter(this.createFilter(queryString)) : serarchList;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    handleSelect () { },
    downHandle () {
      if (this.down) {
        this.down = false
      } else {
        this.down = true
      }
    }
  }
}

</script>
<style lang='scss' scoped>
.dropdown {
  height: 100%;
  margin: 0 18px 0 20px;
  display: flex;
  align-items: center;
}
.avatar {
  width: 44px;
  height: 44px;
  border-radius: 50%;
  overflow: hidden;
}
.pheader {
  width: 100%;
  padding: 6px 0;
  // height: 72px;
  z-index: 1000;
  top: 0px;
  left: 0;
  transition: all 0.4s;
  -moz-transition: all 0.4s;
  -o-transition: all 0.4s;
  -webkit-transition: all 0.4s;
  background: rgba(0, 0, 0, 0.2);
  color: #fff;
  box-shadow: 0;
  position: relative;
  .slideDown {
    position: absolute;
    top: 7rem;
    left: 0;
    height: 0rem;
    width: 100%;
    background: #fff;
    transition: all 0.3s ease;
    overflow: hidden;
    z-index: 999;
    opacity: 0.9;
    // box-shadow: 0 2px 6px 2px #eee;
    .menuList {
      padding: 2rem;
      ul {
        li {
          padding: 0.5rem 0;
          line-height: 1.8;
          text-align: center;
          font-size: 1.4rem;
          color: #999;
          cursor: pointer;
        }
      }
    }
  }
  .activeDown {
    height: 30rem;
  }
  .inner {
    max-width: 150rem;
    padding: 0 4rem;
    height: 7.2rem;
    line-height: 7.2rem;
    margin: 0 auto;
    .left {
      height: 43px;
      .logo {
        padding-right: 1rem;
        cursor: pointer;
      }
      .name {
        height: 4.3rem;
        line-height: 4.3rem;
        border-left: 0.1rem solid #e1e1e1;
        padding-left: 1rem;
        font-size: 1.4rem;
      }
    }
    .center {
      // transform: translateX(-50px);
      flex: 1;
      padding: 0 4.5rem;
      display: flex;
      justify-content: center;
      .nav {
        width: 80%;
        display: flex;

        .item {
          flex: 1;
          text-align: center;
          // padding: 0 3rem;
          // display: inline-block;
          font-size: 1.6rem;
          a {
            color: #fff;
            display: block;
            position: relative;
            &::after {
              content: '';
              width: 20%;
              height: 0.2rem;
              position: absolute;
              bottom: 2rem;
              left: 50%;
              transform: translateX(-50%);
              background: transparent;
            }
            &:hover {
              color: #26b0ff;
              &::after {
                background: #26b0ff;
              }
            }
          }
        }
      }
    }
    .right {
      color: #fff;
      .search {
        margin-right: 1.5rem;
        i {
          color: #fff;
        }
      }
      .border {
        margin-right: 1.5rem;
        width: 0.2rem;
        height: 0.8rem;
        background: #808cea;
      }
      .loginBtn {
        margin-right: 1rem;
        padding: 0 1.5rem;
        height: 3rem;
        line-height: 3rem;
        cursor: pointer;
        border: 0.1rem solid #959fee;
        border-radius: 2rem;
      }
      .regiestBtn {
        padding: 0 1.5rem;
        height: 3.2rem;
        cursor: pointer;
        line-height: 3.2rem;
        background: #26b0ff;
        border-radius: 2rem;
      }
    }
  }
}
.base {
  padding: 0;
  background: #fff;
  box-shadow: rgb(102 102 102) 0px 0px 8px;
  .slideDown {
    top: 5.8rem;
    border-top: 1px solid;
  }
  .inner {
    .center {
      padding: 0 10rem;
      .nav {
        .item {
          a {
            color: #111;
          }
        }
      }
    }
    .right {
      .search {
        margin-right: 1.5rem;
        i {
          color: #111 !important;
        }
      }
      .border {
        background: #999;
      }
      .loginBtn {
        border-color: #ccc;
        color: #111;
      }
    }
  }
}
@media all and (max-width: 1500px) {
  .pheader {
    .inner {
      .center {
        transform: translateX(0px);
        .nav {
          width: 95%;
        }
      }
    }
  }
}
@media all and (max-width: 1256px) {
  .pheader {
    .inner {
      .left {
        .logo {
          img {
            width: 80%;
          }
        }
        .name {
          display: none;
        }
      }
      .center {
        transform: translateX(0px);
        .nav {
          .item {
            font-size: 1.4rem;
            padding: 0 1rem;
          }
        }
      }
      .right {
        .border {
          display: none;
        }
        .search {
          display: none;
        }
      }
    }
  }
}
@media all and (max-width: 996px) {
  .pheader {
    .inner {
      padding: 0 1rem;
      height: 5.8rem;
      line-height: 5.8rem;
      .right {
        .regiestBtn,
        .loginBtn {
          border-radius: 0.2rem;
        }
        .menu {
          width: 3rem;
          height: 6rem;
          display: flex;
          align-items: center;
          cursor: pointer;
          .line {
            margin-left: 1rem;
            width: 2rem;
            height: 0.2rem;
            display: block;
            background: #fff;
            position: relative;
            transition: 0.5s all;

            &::after {
              content: '';
              width: 2rem;
              height: 0.2rem;
              display: block;
              position: absolute;
              top: 0.6rem;
              left: 0;
              background: #fff;
              transition: 0.5s all;
            }
            &::before {
              content: '';
              width: 2rem;
              height: 0.2rem;
              display: block;
              position: absolute;
              top: -0.6rem;
              left: 0;
              background: #fff;
              transition: 0.5s all;
            }
          }

          &:hover {
          }
        }
        .close {
          .line {
            background: rgba(255, 255, 255, 0);
            &::before {
              transform: rotate(45deg);
              top: 0;
            }
            &::after {
              transform: rotate(-45deg);
              top: 0;
            }
          }
        }
      }
    }
  }
  .base {
    .inner {
      .right {
        .menu {
          .line {
            cursor: pointer;
            background: #26b0ff;
            &::before {
              background: #26b0ff;
            }
            &::after {
              background: #26b0ff;
            }
          }
        }
        .close {
          .line {
            background: rgba(255, 255, 255, 0);
            &::before {
              transform: rotate(45deg);
              top: 0;
            }
            &::after {
              transform: rotate(-45deg);
              top: 0;
            }
          }
        }
      }
    }
  }
}
</style>
